// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-consultant-js": () => import("/opt/build/repo/src/templates/consultant.js" /* webpackChunkName: "component---src-templates-consultant-js" */),
  "component---src-templates-book-consultant-js": () => import("/opt/build/repo/src/templates/book-consultant.js" /* webpackChunkName: "component---src-templates-book-consultant-js" */),
  "component---src-templates-chemistry-session-js": () => import("/opt/build/repo/src/templates/chemistry-session.js" /* webpackChunkName: "component---src-templates-chemistry-session-js" */),
  "component---src-templates-search-categories-js": () => import("/opt/build/repo/src/templates/search-categories.js" /* webpackChunkName: "component---src-templates-search-categories-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () => import("/opt/build/repo/src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-callback-js": () => import("/opt/build/repo/src/pages/callback.js" /* webpackChunkName: "component---src-pages-callback-js" */),
  "component---src-pages-chemistry-session-confirmation-js": () => import("/opt/build/repo/src/pages/chemistry-session-confirmation.js" /* webpackChunkName: "component---src-pages-chemistry-session-confirmation-js" */),
  "component---src-pages-consultants-js": () => import("/opt/build/repo/src/pages/consultants.js" /* webpackChunkName: "component---src-pages-consultants-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("/opt/build/repo/src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-meet-our-consultants-js": () => import("/opt/build/repo/src/pages/meet-our-consultants.js" /* webpackChunkName: "component---src-pages-meet-our-consultants-js" */),
  "component---src-pages-test-js": () => import("/opt/build/repo/src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-thank-you-js": () => import("/opt/build/repo/src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

